<template>
  <div class="a-question">
    <draggable :list="questions" class="list-group" ghost-class="ghost" handle=".drag" @start="dragging = true" @end="dragging = false">
      <template v-for="(question, indexQ) in questions">
        <div :ref="'question' + indexQ" :key="indexQ" class="a-question-item">
          <b-card no-body>
            <a-question-header :question="question" :indexQ="indexQ" @load="loadQuestion(indexQ)" @delete="deleteQuestion(indexQ)" />
            <b-collapse v-model="question.visible" :id="'accordion-' + indexQ" :visible="question.visible" accordion="my-accordion" role="tabpanel">
              <a-question-body :ref="'questionBody'" :questions="questions" :indexQ="indexQ" @save="saveQuestion($event, indexQ)" />
            </b-collapse>
          </b-card>
          <div class="z-add-new">
            <b-iconstack class="add" font-scale="2" @click="newQuestion(indexQ)">
              <b-icon stacked icon="circle-fill" style="color: var(--a-color-secondary)" />
              <b-icon stacked icon="plus" style="color: #fff" />
            </b-iconstack>
          </div>
        </div>
      </template>
      <div class="d-flex mt-3 border-top">
        <b-link class="d-inline-flex align-items-center w-color-primary pt-2 pl-0" @click="newQuestion()">
          <div class="a-icon-wrapper --plus --plus-question">
            <img class="mr-2" src="@/plugins/app/_theme/icon/plus.svg" />
          </div>
          <span><strong>Pridať otázku</strong></span>
        </b-link>
      </div>
    </draggable>
  </div>
</template>

<script>
export default {

  components: {
    'draggable':          () => import('vuedraggable'),
    'a-question-header':  () => import('./a-question-header.vue'),
    'a-question-body':    () => import('./a-question-body.vue'),
  },

  props: { 
    questions: { required: true }
  },

  data() {
    return {
      selected: 'choices',
      answerText: ''
    }
  },

  methods: {

    saveQuestion(isValid, index) {
      if (!isValid) {
        return
      }
      this.beforeEditingCache = null
      this.questions[index].visible = false
    },

    loadQuestion(index) {
      if (this.questions[index].visible == false) {
        this.beforeEditingCache = JSON.parse(
            JSON.stringify(this.questions[index])
        )
      }
    },

    async newQuestion(index) {
      let newQuestionIndex
      const question = {
        name: '',
        type: 'choices',
        visible: true,
        choices: [
          { name: '', is_correct: false },
          { name: '', is_correct: false }
        ],
      }
      if (index || index == 0) {
        this.questions.splice(index + 1, 0, question)
        newQuestionIndex = index + 1
      } else {
        newQuestionIndex = this.questions.push(question) - 1
      }
      await this.$nextTick()
      this.$refs[`question${newQuestionIndex}`][0].querySelector(`input[name='nameQ${newQuestionIndex+1}']`).focus()
    },

    deleteQuestion(index) {
      this.questions.splice(index, 1)
    },
  },
}
</script>

<style lang="sass" scoped>
.a-icon-wrapper.--plus-question span
  font-size: 1.125rem
  line-height: 1.125rem
</style>
